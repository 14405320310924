import React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl-next';

import AkButton from '@atlaskit/button/standard-button';
import type { ButtonProps } from '@atlaskit/button/standard-button';
import { token } from '@atlaskit/tokens';
import AkTooltip from '@atlaskit/tooltip/Tooltip';
import LockUnlockedIcon from '@atlaskit/icon/core/lock-unlocked';
import LockedIcon from '@atlaskit/icon/core/migration/lock-locked--lock-filled';

import { useContentType } from '@confluence/page-context';

import { UnlockedIcon } from '../UnlockedIcon';
import { areRestrictionsDisabledForContentType } from '../contentTypeRestrictionsUtils';

const i18n = defineMessages({
	noRestrictions: {
		id: 'restrictions.button.no-restrictions.tooltip',
		defaultMessage: 'No restrictions',
		description: 'Tooltip explaining that the shown icon indicates absence of restrictions',
	},
	editRestrictionsApply: {
		id: 'restrictions.button.edit-restrictions-apply.tooltip',
		defaultMessage: 'Editing restricted',
		description:
			"Tooltip explaining the page has 'everyone can view, only some can edit' restriction mode",
	},
	restrictionsApply: {
		id: 'restrictions.button.restrictions-apply.tooltip',
		defaultMessage: 'Restrictions apply',
		description: 'Tooltip explaining that the shown icon indicates presence of restrictions',
	},
	experimentRestrictions: {
		id: 'restrictions.button.experiment-restrictions.tooltip',
		defaultMessage: 'Upgrade to restrict content access',
		description: 'Tooltip explaining that the user must upgrade in order to change restrictions',
	},
	restrictionsNotApplicableForSmartLinkEmbeds: {
		id: 'restrictions.button.smart-link-embed-restrictions.tooltip',
		defaultMessage: 'Additional restrictions can’t be set for Smart Links',
		description:
			"Tooltip explaining that restrictions can't be set on this particular content type",
	},
});

const getButtonContent = (isRestrictionButtonDisabled, defaulti18nMessage) => {
	return isRestrictionButtonDisabled
		? i18n.restrictionsNotApplicableForSmartLinkEmbeds
		: defaulti18nMessage;
};

const useGenerateButtonProps = (
	props: RestrictionButtonComponentProps,
	contentType: string | undefined,
) => {
	const isRestrictionButtonDisabled = areRestrictionsDisabledForContentType(contentType);

	const intl = useIntl();
	if (props.hasDirectViewRestrictions) {
		// locked
		return {
			content: (
				<FormattedMessage
					{...getButtonContent(isRestrictionButtonDisabled, i18n.restrictionsApply)}
				/>
			),
			icon: (
				<LockedIcon
					spacing="spacious"
					color={token('color.icon.danger', '#DE350B')}
					testId="locked-icon"
					label=""
				/>
			),
			ariaLabel: intl.formatMessage(
				getButtonContent(isRestrictionButtonDisabled, i18n.restrictionsApply),
			),
		};
	}
	if (props.hasInheritedViewRestrictions) {
		// unlocked restricted
		return {
			content: (
				<FormattedMessage
					{...getButtonContent(isRestrictionButtonDisabled, i18n.restrictionsApply)}
				/>
			),
			icon: (
				<LockUnlockedIcon
					color={token('color.icon.danger', '#DE350B')}
					testId="unlocked-restricted-icon"
					label=""
					LEGACY_fallbackIcon={UnlockedIcon}
				/>
			),
			ariaLabel: intl.formatMessage(
				getButtonContent(isRestrictionButtonDisabled, i18n.restrictionsApply),
			),
		};
	}
	// note: the " && !hasDirectViewRestrictions" is excessive, as the have a check above,
	// but it's just easier to read this way.
	if (props.hasRestrictions && !props.hasDirectViewRestrictions) {
		return {
			content: (
				<FormattedMessage
					{...getButtonContent(isRestrictionButtonDisabled, i18n.editRestrictionsApply)}
				/>
			),
			icon: (
				<LockUnlockedIcon
					color={token('color.icon', '#42526E')}
					testId="unlocked-icon"
					label=""
					LEGACY_fallbackIcon={UnlockedIcon}
				/>
			),
			ariaLabel: intl.formatMessage(
				getButtonContent(isRestrictionButtonDisabled, i18n.editRestrictionsApply),
			),
		};
	}
	// unlocked
	return {
		content: (
			<FormattedMessage {...getButtonContent(isRestrictionButtonDisabled, i18n.noRestrictions)} />
		),
		icon: <LockUnlockedIcon testId="unlocked-icon" label="" LEGACY_fallbackIcon={UnlockedIcon} />,
		ariaLabel: intl.formatMessage(
			getButtonContent(isRestrictionButtonDisabled, i18n.noRestrictions),
		),
	};
};

export type RestrictionButtonComponentProps = {
	hasDirectViewRestrictions: boolean;
	hasInheritedViewRestrictions: boolean;
	hasRestrictions: boolean;
	onClick;
} & Pick<ButtonProps, 'isDisabled'>;

export const RestrictionButtonComponent = (props: RestrictionButtonComponentProps) => {
	const [contentType] = useContentType();

	const { content, icon, ariaLabel } = useGenerateButtonProps(props, contentType);

	const handleOnClick = (...args) => {
		props.onClick?.(...args);
	};

	return (
		<AkTooltip content={content}>
			<AkButton
				appearance="subtle"
				data-test-id="restrictions.dialog.button"
				onClick={handleOnClick}
				isDisabled={props.isDisabled}
				iconBefore={icon}
				aria-label={ariaLabel}
				data-id="restrictions-button"
				style={
					// The button's icon's svg isn't in itself forcing a
					// minimum button width, so if we don't manually apply one,
					// it'll get condensed when squeezed (for example by the
					// big web item you'll to its left see on our integration
					// test servers)
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					{ width: '32px' }
				}
			/>
		</AkTooltip>
	);
};
